import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import firebase from '../../firebase'
import styled from 'styled-components'
import Slider from 'react-slick'

// Slick Slider files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Components
import Navigation from '../navigation'
import InputSelector from './input-selector'
import LocationInput from './location-input'
import SvgViewer from '../svg-viewer'
import Footer from '../footer'

// Helpers
import { I18nContext } from "../../i18n";
import { device } from '../../constants/responsive';

// Assets
import Arrow from "../view-entry/arrow.svg";

const AddEntryForm = () => {
  const { translate } = useContext(I18nContext);

  const [speak, setSpeak] = useState([])
  const [think, setThink] = useState([])
  const [feel, setFeel] = useState([])
  const [dream, setDream] = useState([])

  const [totalSpeak, updateTotalSpeak] = useState()
  const [totalThink, updateTotalThink] = useState()
  const [totalFeel, updateTotalFeel] = useState()
  const [totalDream, updateTotalDream] = useState()

  const [location, setLocation] = useState('')

  const isEnabled = formFilled();
  const [submissionReady, setSubmissionReady] = useState(false);
  const history = useHistory();

  const [entriesTotal, setEntriesTotal] = useState(0);

  function formFilled() {
    return (speak && think && feel && dream && location);
  }

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("entries")
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) => {
        const newEntries = snapshot.docs.map((doc) => ({
          id: doc.id
        }));
        setEntriesTotal(newEntries.length);
      });
    return () => unsubscribe();
  }, []);

  function onSubmit(e) {
    e.preventDefault();
    firebase
      .firestore()
      .collection("entries")
      .add({
        speak,
        think,
        feel,
        dream,
        location,
        entryNumber: entriesTotal + 1,
        timestamp: Date()
      })
      .then((e) => {
        history.push("/entry/" + e.id);
      })
      .catch(function (error) {
        console.error("Error adding entry: ", error);
      });
  }

  // if form is correctly filled out, authenticate
  isEnabled && firebase.app().auth().signInAnonymously();

  firebase.auth().onAuthStateChanged(function (user) {
    if (user && isEnabled) {
      setSubmissionReady(true);
    }
  });

  const entrySlider = React.createRef();

  const gotoNext = event => {
    event.preventDefault();
    entrySlider.current.slickNext();
  }

  const gotoStart = event => {
    event.preventDefault();
    entrySlider.current.slickGoTo(1);
  }

  const sliderSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    arrows: false
  };

  return (
    <div className="container">
      <Navigation />
      <AddEntrySC>
        <ShapePreview totalSpeak={totalSpeak}>
          <SvgViewer
            totalSpeak={totalSpeak}
            totalThink={totalThink}
            totalFeel={totalFeel}
            totalDream={totalDream}
          />
        </ShapePreview>
        <FormWrapper>
          <EntryForm onSubmit={onSubmit}>
            <Slider {...sliderSettings} ref={entrySlider}>

              <FormQuestion>
                <QuestionHeader>{translate("nav_participate")}</QuestionHeader>
                <p>{translate("participate_overview")}</p>
                <div className="input-wrapper intro-slide">
                  <button
                    className="link-next-slide"
                    onClick={(event) => gotoNext(event)}
                  >
                    <img src={Arrow} alt="Next" />
                  </button>
                </div>
              </FormQuestion>
              <FormQuestion>
                <QuestionHeader>
                  {translate("participate_q1_title")}
                </QuestionHeader>
                <p>{translate("participate_q1_text")}</p>
                <div className="input-wrapper">
                  <InputSelector
                    setCategory={setSpeak}
                    languageTotal={updateTotalSpeak}
                  />
                  <button
                    className="link-next-slide"
                    onClick={(event) => gotoNext(event)}
                    disabled={!totalSpeak > 0}
                  >
                    <img src={Arrow} alt="Next" />
                  </button>
                </div>
                <HelperText>
                  <p>
                    {translate("participate_helper1")}
                    <br />
                    {translate("participate_helper2")}
                  </p>
                </HelperText>
              </FormQuestion>

              <FormQuestion>
                <QuestionHeader>
                  {translate("participate_q2_title")}
                </QuestionHeader>
                <p>{translate("participate_q2_text")}</p>
                <div className="input-wrapper">
                  <InputSelector
                    setCategory={setThink}
                    languageTotal={updateTotalThink}
                  />
                  <button
                    className="link-next-slide"
                    onClick={(event) => gotoNext(event)}
                    disabled={!totalThink > 0}
                  >
                    <img src={Arrow} alt="Next" />
                  </button>
                </div>
                <HelperText>
                  <button onClick={(event) => gotoStart(event)}>&#8635;</button>
                </HelperText>
              </FormQuestion>

              <FormQuestion>
                <QuestionHeader>
                  {translate("participate_q3_title")}
                </QuestionHeader>
                <p>{translate("participate_q3_text")}</p>
                <div className="input-wrapper">
                  <InputSelector
                    setCategory={setFeel}
                    languageTotal={updateTotalFeel}
                  />
                  <button
                    className="link-next-slide"
                    onClick={(event) => gotoNext(event)}
                    disabled={!totalFeel > 0}
                  >
                    <img src={Arrow} alt="Next" />
                  </button>
                </div>
                <HelperText>
                  <button onClick={(event) => gotoStart(event)}>&#8635;</button>
                </HelperText>
              </FormQuestion>

              <FormQuestion>
                <QuestionHeader>
                  {translate("participate_q4_title")}
                </QuestionHeader>
                <p>{translate("participate_q4_text")}</p>
                <div className="input-wrapper">
                  <InputSelector
                    setCategory={setDream}
                    languageTotal={updateTotalDream}
                  />
                  <button
                    className="link-next-slide"
                    onClick={(event) => gotoNext(event)}
                    disabled={!totalDream > 0}
                  >
                    <img src={Arrow} alt="Next" />
                  </button>
                </div>
                <HelperText>
                  <button onClick={(event) => gotoStart(event)}>&#8635;</button>
                </HelperText>
              </FormQuestion>

              {/* <FormQuestion className="location-form">
                <QuestionHeader>
                  {translate("participate_q5_title")}
                </QuestionHeader>
                <p>{translate("participate_q5_text")}</p>
                <div className="input-wrapper">
                  <AddEntryLocation
                    className="location-entry"
                    setLocation={setLocation}
                    setGeolocation={setGeolocation}
                  />
                  <SubmitButton disabled={!isEnabled && !submissionReady}>
                    {translate("participate_submit")}
                  </SubmitButton>
                </div>
                <HelperText className="location-helper">
                  <button onClick={(event) => gotoStart(event)}>&#8635;</button>
                </HelperText>
              </FormQuestion> */}

              <FormQuestion className="location-form">
                <QuestionHeader>
                  {translate("participate_q5_title")}
                </QuestionHeader>
                <p>{translate("participate_q5_text")}</p>
                <div className="input-wrapper">
                  <LocationInput
                    className="location-entry"
                    setLocation={setLocation}
                  />
                  <SubmitButton disabled={!isEnabled && !submissionReady}>
                    {translate("participate_submit")}
                  </SubmitButton>
                </div>
                <HelperText className="location-helper">
                  <p>
                    {translate("participate_helper2")}
                  </p>
                  <button onClick={(event) => gotoStart(event)}>&#8635;</button>
                </HelperText>
              </FormQuestion>

            </Slider>
          </EntryForm>
        </FormWrapper>
      </AddEntrySC>
      <Footer />
    </div>
  );
}

const ShapePreview = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  flex: 0 0 200px;
  border-radius: 15px;
  margin-top: 25px;

  @media ${device.tablet} {
    margin: 0 50px 0 0;
  }

  ${({ totalSpeak }) =>
    !totalSpeak &&
    `
    border: 3px solid #5d4749;
  `}
`;

const EntryForm = styled.form`
  position: relative;
  width: 90vw;
  margin-top: 25px;
  height: 275px;

  @media ${device.tablet} {
    height: 400px;
    max-width: 500px;
    margin-top: 200px;
  }
`;

const FormWrapper = styled.div`
  flex: 0 0 auto;

  .slick-slider {
    height: 100%;

    .slick-list {
      height: 100%;

      .slick-slide > div > div {
        outline: none;
      }
    }
  }
`;

const AddEntrySC = styled.div`
  flex: 0 0 auto;
  width: 90vw;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const SubmitButton = styled.button`
  flex: 0 0 auto;
  background: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border: 1px solid black;
  outline: none;

  &:disabled {
    color: #ccc;
    background: #e6e6e6;
    cursor: auto;
    border: 1px solid #e6e6e6;
  }
`;

const QuestionHeader = styled.p`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2rem;
`;

const FormQuestion = styled.div`
  .input-wrapper {
    display: flex;

    > div {
      flex: 0 0 calc(90vw - 60px);
      margin-right: 35px;
      max-width: 440px;
      width: unset;
    }

    .link-next-slide {
      flex: 0 0 25px;
      margin: 25px 1px 0 0;
      background: none;
      border: none;
      padding: 0;
      outline: none !important;

      &:disabled {
        img {
          opacity: 0.25;
        }
      }

      &:hover {
        cursor: pointer;
      }

      img {
        transform: rotate(180deg);
        width: 25px;
      }
    }

    &.intro-slide {
      justify-content: flex-start;

      @media ${device.tablet} {
        justify-content: flex-end;
      }
    }
  }

  &.location-form .input-wrapper {
    > div {
      margin-right: 5px;
    }
  }

  p {
    @media ${device.tablet} {
      width: 90%;
    }
  }
`;

const HelperText = styled.div`
  font-size: 1.2rem;
  color: lightslategray;
  font-style: italic;
  padding-left: 10px;
  padding-right: 60px;
  line-height: 150%;

  &.location-helper {
    margin-top: 12px;
  }

  button {
    border: none;
    background: none;
    font-size: 2.5rem;
    color: lightslategray;
    padding: 0;
    margin: 0;
    line-height: 0;
    margin-top: 0;
    float: right;
    margin-right: 10px;
    color: #cccccc;
    font-weight: bold;

    &:hover {
      cursor: pointer;
      color: black;
    }
  }
`;

export default AddEntryForm
