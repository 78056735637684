import React, { useContext } from "react";
import styled from "styled-components";

// Helpers
import { I18nContext } from '../i18n'
import { device } from '../constants/responsive';

const Footer = () => {
  const { translate } = useContext(I18nContext);

  return (
    <FooterSC>
      <p>© 2020 {translate("about_artists_name")}</p>
    </FooterSC>
  );
};

const FooterSC = styled.div`
  width: 100%;
  height: 30px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-bottom: 0;
    font-size: 1.2rem;
  }

  @media ${device.laptop} {
    height: 50px;
    padding: 20px 0;
  }
`;

export default Footer;
