import React from "react";
import styled from "styled-components";

const EntryContentCategory = ({ text, cat, data }) => {

  return (
    <LanguageGroup>
      <h4>{text}:</h4>
      <p>
        {data.map((think, index) => {
          return (
            <span key={index}>
              {think}
              {index < data.length - 1 ? ",\u00A0" : ""}
            </span>
          );
        })}
      </p>
    </LanguageGroup>
  );
};

const LanguageGroup = styled.div`
  margin-top: 12px;

  span {
    text-transform: capitalize;
    display: inline-block;
  }
`;

export default EntryContentCategory;
