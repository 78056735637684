import React, { useContext } from 'react'
import CreatableSelect from 'react-select/creatable'

// Helpers
import { I18nContext } from "../../i18n";

const LocationInput = ({ setLocation }) => {

  const { translate } = useContext(I18nContext);

  const minimalStyle = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? 0 : 0,
      border: "none",
      "&:hover": {
        borderColor: state.isFocused ? "none" : "none",
      },
      borderBottom: state.isFocused ? "1px solid #ccc" : "1px solid black",
      borderRadius: "0px",
      background: "#fbfcf6",
      fontSize: "1.6rem",
      zIndex: "9999",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display: "none",
      fontSize: "1.6rem",
    }),
    menuPortal: (base) => ({ ...base, zIndex: '9999 !important' }),
    menu: (provided) => ({ ...provided, display: "none", zIndex: '9999 !important' })
  };



  return (
    <div>
      <CreatableSelect
        placeholder={translate("participate_type")}
        isClearable
        styles={minimalStyle}
        onChange={(value) => {
          value && setLocation(value.value);
        }}
      />
    </div>
  );
}

export default LocationInput