import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

// Components
import Navigation from '../navigation'
import EntryContent from './entry-content'
import Footer from '../footer'

//  Assets
import Loading from '../../assets/loading.svg'

const ViewEntry = () => {
  let params = useParams();
  const singleId = params.singleId;
  const [entry, setEntry] = useState([]);
  const [entries, setEntries] = useState([]);
  const [position, setPosition] = useState(1);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("entries")
      .orderBy("entryNumber", "asc")
      .onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEntries(data);
      });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (entries.length > 0) {
      const entryPosition = getIndex(singleId, entries, 'id');
      setEntry(entries[entryPosition]);
      setPosition(entryPosition + 1);
    }
  }, [entries, entry, singleId]);

  /**
   *  Get the index location of the current entry
   */
  function getIndex(value, arr, prop) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return -1; // in case the value doesn't exist
  }

  let nextLink = '';
  let prevLink = '';

  if (entry && entries.length > 0) {
    if (position) {
      let nextID = "";
      let prevID = "";

      if (position === entries.length) {
        nextID = entries[0].id;
      } else {
        nextID = entries[position].id;
      }

      if (position === 1) {
        prevID = entries[entries.length - 1].id;
      }
      else {
        prevID = entries[position - 2].id;
      }

      nextLink = '/entry/' + nextID;
      prevLink = '/entry/' + prevID;
    }
  }

  return (
    <div className="container">
      <Navigation />
      <ViewEntrySC>
        {entry && entries.length > 0 ? (
          <EntryContent entry={entry} nextLink={nextLink} prevLink={prevLink} />
        ) : (
          <LoadingContainer>
            <img src={Loading} alt="Loading..." />
          </LoadingContainer>
        )}
      </ViewEntrySC>
      <Footer />
    </div>
  );
};

const ViewEntrySC = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoadingContainer = styled.div`
  grid-column: 1 / -1;
  text-align: center;
`;


export default ViewEntry;
