import React, { useContext } from "react";
import styled from "styled-components";

import Filter from "./filter";

// Helpers
import { I18nContext } from "../i18n";

const FilterContainer = ({
  speakFilter,
  thinkFilter,
  feelFilter,
  dreamFilter,
  locationFilter,
  setFilteredEntries,
  entries,
  updateFilterTerms,
  setToggle
}) => {

  const { translate } = useContext(I18nContext);

  function filterIt(value, cat) {
    setFilteredEntries(entries);
    if (value !== "clear") {
      const filtered = entries.filter((entry) => entry[cat].includes(value));
      setFilteredEntries(filtered);
      updateFilterTerms([cat, value]);
      setToggle(false);
      return filtered;
    } else {
      return;
    }
  }

  return (
    <FilterContainerSC>
      <div>
        <p>
          {translate("entry_overview_speak")} <span>></span>
        </p>
        <Filter
          category="speak"
          filter={speakFilter}
          filterIt={filterIt}
        />
      </div>
      <div>
        <p>
          {translate("entry_overview_think")} <span>></span>
        </p>
        <Filter
          category="think"
          filter={thinkFilter}
          filterIt={filterIt}
        />
      </div>
      <div>
        <p>
          {translate("entry_overview_feel")} <span>></span>
        </p>
        <Filter
          category="feel"
          filter={feelFilter}
          filterIt={filterIt}
        />
      </div>
      <div>
        <p>
          {translate("entry_overview_dream")} <span>></span>
        </p>
        <Filter
          category="dream"
          filter={dreamFilter}
          filterIt={filterIt}
        />
      </div>
      <div>
        <p>
          {translate("entry_overview_location")} <span>></span>
        </p>
        <Filter
          category="location"
          filter={locationFilter}
          filterIt={filterIt}
        />
      </div>
    </FilterContainerSC>
  );
};

const FilterContainerSC = styled.div`
  position: absolute;
  margin-top: 10px;

  div {
    position: relative;
    min-width: 90px;
    border: 1px solid black;

    &:hover {
      cursor: pointer;
      ul {
        display: block;
      }
    }

    p {
      font-weight: normal;
      font-size: 1.2rem;
      padding: 10px;
      margin-bottom: 0;
      background-color: #d1d1d1;

      span {
      }

      & + p {
        border-top: 1px solid black;
      }
    }

    ul {
      display: none;
      position: absolute;
      left: 90px;
      top: -1px;
      width: 100px;
      flex-direction: column;
      border: 1px solid blue;
      list-style-type: none;
      background-color: #ffffff;
      border: 1px solid black;
      font-size: 1.2rem;
      text-transform: capitalize;
      padding: 10px 20px;
      height: 310px;
      overflow: scroll;

      li {
        text-transform: capitalize;
        color: black;
        text-align: left;

        & + li {
          margin-top: 5px;

          a,
          a:visited {
            color: black;
          }

          a:hover {
            color: #d1d1d1;
          }
        }
      }

      &.location {
        width: 150px;

        li + li {
          margin-top: 10px;
        }
      }
    }
  }
`;

export default FilterContainer;
