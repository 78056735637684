import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-tiger-transition'

// Helpers
import { I18nContext } from "../i18n";
import { device } from "../constants/responsive";

// Components
import LanguageSelect from './language-select'

// Assets
import Logo from '../assets/jaali-logo.svg'
import LogoLight from '../assets/jaali-logo-light.svg'

const Navigation = (getPage) => {
  const isAbout = getPage.page;
  const { translate } = useContext(I18nContext);
  const currentLanguage = localStorage.getItem('language');

  if (
    currentLanguage === "ar" ||
    currentLanguage === "fa" ||
    currentLanguage === "ur" ||
    currentLanguage === "he" ||
    currentLanguage === "yi"
  ) {
    document.body.classList.add("rtl");
  } else {
    document.body.classList.remove("rtl");
  }

  return (
    <NavigationSC page={isAbout}>
      <LogoBlock>
        {isAbout === "about" ? (
          <img src={LogoLight} alt="JAALI" />
        ) : (
          <img src={Logo} alt="JAALI" />
        )}
      </LogoBlock>
      <ul>
        <li>
          <Link className="nav-link" transition="glide-right" to="/about">
            {translate("nav_about")}
          </Link>
        </li>
        <li>
          <Link className="nav-link" transition="fade" to="/">
            {translate("nav_gallery")}
          </Link>
        </li>
        <li className="nav-subtitle">
          <Link className="nav-link" transition="fade" to="/">
            {translate("jaali_subtitle")}
          </Link>
        </li>
        <li>
          {isAbout === "about" ? (
            <LanguageSelect page="about" />
          ) : (
            <LanguageSelect />
          )}
        </li>
        <li>
          <Link className="nav-link" transition="glide-left" to="/new">
            {translate("nav_participate")} +
          </Link>
        </li>
      </ul>
    </NavigationSC>
  );
}

const NavigationSC = styled.div`
  width: 100vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media ${device.laptop} {
    margin-top: 50px;
  }

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    width: 90vw;
    padding: 0;
    margin-top: 8px;

    li {
      flex: 0 0 25%;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      color: black;
      font-size: 1.2rem;

      &.nav-subtitle {
        display: none;

        @media ${device.tablet} {
          display: block;
        }
      }

      &:nth-child(1),
      &:nth-child(2) {
        text-align: left;
      }

      &:nth-child(4) {
        flex: 0 0 20%;
      }

      &:nth-child(5) {
        flex: 0 0 30%;
      }
    }

    @media ${device.tablet} {
      li {
        flex: 0 0 20%;
        text-align: left;

        &:nth-child(3) {
          flex: 1 0 auto;
          text-align: center;
        }

        &:nth-child(4),
        &:nth-child(5) {
          text-align: right;
          flex: 0 0 20%;
        }
      }
    }

    @media ${device.laptop} {
      li {
        font-size: 1.6rem;
      }
    }
  }

  .nav-link {
    color: #5d4749;
    text-transform: uppercase;
    font-weight: 700;
    transition: all 100ms ease-in-out;

    ${({ page }) =>
    page === "about" &&
    `
        color: white;
      `}

    &:hover {
      color: #666666;
      border-bottom: 1px solid #666666;
    }
  }
`;

const LogoBlock = styled.div`
  width: 90vw;
  display: flex;
  justify-content: center;

  img {
    width: 50vw;
    max-width: 150px;

    ${device.tablet} {
      max-width: 200px;
    }
`;

export default Navigation
