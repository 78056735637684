import React from "react";

import Speak01 from "./pngs/speak-01.png";
import Speak02 from "./pngs/speak-02.png";
import Speak03 from "./pngs/speak-03.png";
import Speak04 from "./pngs/speak-04.png";
import Speak05 from "./pngs/speak-05.png";
import Speak06 from "./pngs/speak-06.png";
import Speak07 from "./pngs/speak-07.png";
import Speak08 from "./pngs/speak-08.png";
import Speak09 from "./pngs/speak-09.png";
import Speak10 from "./pngs/speak-10.png";

const SpeakForm = (props) => {
  switch (props.number) {
    case "0":
      return <img src={Speak01} alt="Symbol for speaking in 1 language" />;
    case "1":
      return <img src={Speak01} alt="Symbol for speaking in 1 language" />;
    case "2":
      return <img src={Speak02} alt="Symbol for speaking in 2 languages" />;
    case "3":
      return <img src={Speak03} alt="Symbol for speaking in 3 languages" />;
    case "4":
      return <img src={Speak04} alt="Symbol for speaking in 4 languages" />;
    case "5":
      return <img src={Speak05} alt="Symbol for speaking in 5 languages" />;
    case "6":
      return <img src={Speak06} alt="Symbol for speaking in 6 languages" />;
    case "7":
      return <img src={Speak07} alt="Symbol for speaking in 7 languages" />;
    case "8":
      return <img src={Speak08} alt="Symbol for speaking in 8 languages" />;
    case "9":
      return <img src={Speak09} alt="Symbol for speaking in 9 languages" />;
    default:
      return <img src={Speak10} alt="Symbol for speaking in 10 languages" />;
  }
};

export default SpeakForm;
