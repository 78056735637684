import React from "react";

import Feel01 from "./pngs/feel-01.png";
import Feel02 from "./pngs/feel-02.png";
import Feel03 from "./pngs/feel-03.png";
import Feel04 from "./pngs/feel-04.png";
import Feel05 from "./pngs/feel-05.png";
import Feel06 from "./pngs/feel-06.png";
import Feel07 from "./pngs/feel-07.png";
import Feel08 from "./pngs/feel-08.png";
import Feel09 from "./pngs/feel-09.png";
import Feel10 from "./pngs/feel-10.png";

const FeelForm = (props) => {
  switch (props.number) {
    case "0":
      return <img src={Feel01} alt="Symbol for feeling in 1 language" />;
    case "1":
      return <img src={Feel01} alt="Symbol for feeling in 1 language" />;
    case "2":
      return <img src={Feel02} alt="Symbol for feeling in 2 languages" />;
    case "3":
      return <img src={Feel03} alt="Symbol for feeling in 3 languages" />;
    case "4":
      return <img src={Feel04} alt="Symbol for feeling in 4 languages" />;
    case "5":
      return <img src={Feel05} alt="Symbol for feeling in 5 languages" />;
    case "6":
      return <img src={Feel06} alt="Symbol for feeling in 6 languages" />;
    case "7":
      return <img src={Feel07} alt="Symbol for feeling in 7 languages" />;
    case "8":
      return <img src={Feel08} alt="Symbol for feeling in 8 languages" />;
    case "9":
      return <img src={Feel09} alt="Symbol for feeling in 9 languages" />;
    default:
      return <img src={Feel10} alt="Symbol for feeling in 10 languages" />;
  }
};

export default FeelForm;
