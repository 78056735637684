import React from 'react'
import './App.css'
import { Navigation, Route, glide, fade } from "react-tiger-transition";
import "react-tiger-transition/styles/main.min.css";

import Gallery    from './components/gallery';
import About      from './components/about';
import ViewEntry  from './components/view-entry';
import AddEntry   from './components/add-entry';

function App() {
  return (
    <Navigation>
      <Route exact path="/">
        <Gallery />
      </Route>
      <Route exact path="/archive">
        <Gallery />
      </Route>
      <Route exact path="/new">
        <AddEntry />
      </Route>
      <Route path="/entry/:singleId">
        <ViewEntry />
      </Route>
      <Route exact path="/about">
        <About />
      </Route>
    </Navigation>
  );
}

glide({
  name: 'glide-bottom',
  direction: 'bottom'
});
glide({
  name: 'glide-top',
  direction: 'top'
});
glide({
  name: "glide-right",
  direction: "right",
});
glide({
  name: "glide-left",
  direction: "left",
});

fade({
  name: 'fade',
	direction: 'top'
});


export default App
