import React from "react";

import Dream01 from "./pngs/dream-01.png";
import Dream02 from "./pngs/dream-02.png";
import Dream03 from "./pngs/dream-03.png";
import Dream04 from "./pngs/dream-04.png";
import Dream05 from "./pngs/dream-05.png";
import Dream06 from "./pngs/dream-06.png";
import Dream07 from "./pngs/dream-07.png";
import Dream08 from "./pngs/dream-08.png";
import Dream09 from "./pngs/dream-09.png";
import Dream10 from "./pngs/dream-10.png";

const DreamForm = (props) => {
  switch (props.number) {
    case "0":
      return <img src={Dream01} alt="Symbol for dreaming in 1 language" />;
    case "1":
      return <img src={Dream01} alt="Symbol for dreaming in 1 language" />;
    case "2":
      return <img src={Dream02} alt="Symbol for dreaming in 2 languages" />;
    case "3":
      return <img src={Dream03} alt="Symbol for dreaming in 3 languages" />;
    case "4":
      return <img src={Dream04} alt="Symbol for dreaming in 4 languages" />;
    case "5":
      return <img src={Dream05} alt="Symbol for dreaming in 5 languages" />;
    case "6":
      return <img src={Dream06} alt="Symbol for dreaming in 6 languages" />;
    case "7":
      return <img src={Dream07} alt="Symbol for dreaming in 7 languages" />;
    case "8":
      return <img src={Dream08} alt="Symbol for dreaming in 8 languages" />;
    case "9":
      return <img src={Dream09} alt="Symbol for dreaming in 9 languages" />;
    default:
      return <img src={Dream10} alt="Symbol for dreaming in 10 languages" />;
  }
};

export default DreamForm;
