import React, { useContext } from 'react'
import { useHistory, Link } from "react-router-dom"
import styled from 'styled-components'
import { EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon} from "react-share"

// Components
import PngViewer from '../svg-viewer/png-viewer'
import EntryContentCategory from './entry-content-category'

// Helpers
import { I18nContext } from "../../i18n";
import { device } from "../../constants/responsive";

// Assets
import Arrow from "./arrow.svg"

const EntryContent = ({ entry, prevLink, nextLink }) => {
  const history = useHistory();
  const shareUrl = window.location.href;
  const { translate } = useContext(I18nContext);

  function closeCard() {
    history.push('/');
  }

  return (
    <EntryContentSC>
      <Link className="nav-arrow arrow-previous" to={prevLink}>
        <img src={Arrow} alt="Go to previous entry" />
      </Link>
      <EntryCard>
        <Link
          className="back-button"
          transition="flip"
          to="/"
          onClick={closeCard}
        >
          &#x2612;
        </Link>
        {entry.think && entry.think.length && (
          <EntryContainer>
            <h4 className="jaali-id">
              {translate("jaali")} #{entry.entryNumber}
            </h4>
            <CardContent>
              <div className="entry-content-symbol">
                <PngViewer
                  totalSpeak={entry.speak.length}
                  totalThink={entry.think.length}
                  totalFeel={entry.feel.length}
                  totalDream={entry.dream.length}
                />
              </div>
              <div className="entry-content-data">
                <h4>{translate("entry_overview")}</h4>
                <EntryContentCategory
                  text={translate("entry_overview_speak")}
                  cat="Speak"
                  data={entry.speak}
                />
                <EntryContentCategory
                  text={translate("entry_overview_think")}
                  cat="Think"
                  data={entry.think}
                />
                <EntryContentCategory
                  text={translate("entry_overview_feel")}
                  cat="Feel"
                  data={entry.feel}
                />
                <EntryContentCategory
                  text={translate("entry_overview_dream")}
                  cat="Dream"
                  data={entry.dream}
                />
                <hr />
                <div className="location">
                  <p>
                    Location: <br />
                    {entry.location}
                  </p>
                </div>
                <hr />
                <ShareBox>
                  <span>{translate("entry_overview_share")} →</span>
                  <FacebookShareButton
                    url={shareUrl}
                    quote="Look at this scriptograph created using JAALI, an interactive installation for the 2020 Indian Summer Festival."
                  >
                    <FacebookIcon size={24} round />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={shareUrl}
                    title="Look at this scriptograph created using JAALI, an interactive installation for the 2020 Indian Summer Festival."
                  >
                    <TwitterIcon size={24} round />
                  </TwitterShareButton>
                  <EmailShareButton
                    url={shareUrl}
                    subject="JAALI – Patterns of Speech"
                    body="Look at this scriptograph created using JAALI, an interactive installation for the 2020 Indian Summer Festival."
                  >
                    <EmailIcon size={24} round />
                  </EmailShareButton>
                </ShareBox>
              </div>
            </CardContent>
          </EntryContainer>
        )}
      </EntryCard>
      <Link className="nav-arrow arrow-next" to={nextLink}>
        <img src={Arrow} alt="Go to next entry" />
      </Link>
    </EntryContentSC>
  );
};

const EntryContentSC = styled.div`
  flex: 1 0 auto;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .nav-arrow {
    img {
      width: 25px;
    }

    &.arrow-next {
      transform: rotate(180deg);
    }
  }
`;

const EntryCard = styled.div`
  background: white;
  width: 70vw;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border: 1px solid #5d4749;
  position: relative;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: row;
    align-items: flex-start;
    max-width: 700px;
    width: 75vw;
    padding: 40px;
  }

  .jaali-id {
    text-align: center;

    @media ${device.tablet} {
      text-align: unset;
    }
  }

  .back-button {
    position: absolute;
    top: 5px;
    right: 5px;
    transition: all 100ms ease-in-out;
    font-size: 2.5rem;
    color: black;
    line-height: 75%;

    &:hover {
      color: #666;
    }
  }

  .entry-content-symbol {
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 150px;
    margin: 10px 0;
    flex: 0 0 45%;

    // special condition for small phones
    @media (max-width: 400px) {
      width: 125px;
      height: 125px;
    }

    @media ${device.tablet} {
      margin-bottom: 0;
    }

    > div {
      max-width: 300px;
      flex: 1 0 100%;
      height: 100%;
    }
  }

  .entry-content-data {
    width: 80%;
    max-width: 350px;
    text-align: left;

    @media ${device.mobile} {
      p {
        margin-bottom: 6px;
      }
    }

    @media ${device.tablet} {
      flex: 0 0 45%;
    }

    h4::first-of-type {
      margin-bottom: 18px;
    }
  }

  hr {
    border-top: 1px solid #5d4749;
    border-bottom: 0;
    margin-bottom: 6px;

    @media ${device.tablet} {
      margin-bottom: 12px;
    }
  }

  .location {
    p {
      font-size: 1.2rem;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
  }
`;

const EntryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: row;
    align-items: unset;
  }
`;

const ShareBox = styled.div`
  background: transparent;
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: max-content;

  @media ${device.tablet} {
    margin-top: 18px;
  }

  span {
    font-weight: bold;
  }

  > * {
    margin-right: 5px;
  }
`;

export default EntryContent;
