import React, { useContext, useEffect } from "react";
import styled from "styled-components";

import Navigation from './navigation'

// Helpers
import { I18nContext } from "../i18n";
import { device } from "../constants/responsive";

// Assets
import Poster from '../assets/about-header.jpg'
import ISFLogo from '../assets/isf-logo.png'

const About = () => {
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    setTimeout(() => {
      document.body.id = 'about';
      // get height of About page and set it to correct for react-tiger overflow
      document.getElementById("about").style.overflow = "visible";
      const pageHeight = (document.getElementsByClassName("container")[0].clientHeight + 100) + "px";
      document.getElementById("root").style.height = pageHeight;
    }, 1000);

    return () => { document.body.id = ''; document.getElementById('root').style.height = "100vh"; }
  });

  return (
    <AboutContainer className="container about">
      <Navigation page="about" />
      <AboutPoster>
        <img src={Poster} alt="About JAALI" />
      </AboutPoster>
      <AboutContent>
        <Block>
          <h3>{translate("about_overview")}</h3>
        </Block>
        <Block>
          <p className="two-col">{translate("about_paragraph1")}</p>
          <p className="two-col">{translate("about_paragraph2")}</p>
        </Block>
        <Block>
          <p>
            <em>{translate("about_quote")}</em>
          </p>
        </Block>
        <Block>
          <CreditsLogo>
            <a
              href="https://www.indiansummerfest.ca/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ISFLogo} alt="Indian Summer Festival" />
            </a>
          </CreditsLogo>
          <CreditsInfo>
            <ArtistHeader>{translate("about_artists_title")} +</ArtistHeader>
            <ArtistsMain>{translate("about_artists_name")}</ArtistsMain>
            <p>{translate("about_artists_bio")}</p>
            <ArtistHeader>{translate("about_coding_title")}</ArtistHeader>
            <p>{translate("about_coding_name")}</p>
            <ArtistHeader>Volunteer Translators</ArtistHeader>
            <p>ISF and the artists extend our sincere and deep gratitude to the many volunteers who brought linguistic richness to this project with their translations, and gave it the chance to grow.</p>
            <p>
              Afroza Breti<br />
              Akshra Atrey<br />
              Alexandra Gordeyeva<br />
              Alex Delamaire<br />
              Anna Ling Kaye<br />
              Arash Moghadam<br />
              Baljeet Singh<br />
              Bari Asa’ad
              Carmel Tanaka<br />
              Carmen Rodriguez<br />
              Carolina Bergonzoni<br />
              Delaram Arabi<br />
              Faith Jones
              Haruho Kubota<br />
              Julia Prezotto<br />
              Mei-lang Hsu<br />
              Mohammad Rafiq<br />
              Neemay Li
              Nicole Nozick<br />
              Samrudhi Surve<br />
              Reeam Mawasey
              </p>
          </CreditsInfo>
        </Block>
      </AboutContent>
    </AboutContainer>
  );
}

const AboutContainer = styled.div`
  background: #15120f;
`;

const AboutPoster = styled.div`
  width: 100vw;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-top: 75px;

  img {
    width: 80vw;
    max-width: 900px;
  }
`;

const AboutContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
  background: #0d0d0d;
`;

const Block = styled.div`
  width: 80vw;
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 50px;

  h3 {
    color: #ec7c70;
    line-height: 140%;
    font-weight: normal;
    font-size: 1.8rem;

    @media ${device.tablet} {
      font-size: 2.3rem;
    }
  }

  p {
    color: white;
    font-size: 1.5rem;

    &.two-col {
      flex: 0 0 100%;

      @media ${device.tablet} {
        flex: 0 0 48%;
      }
    }
  }
`;

const CreditsLogo = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 0 0 100%;
  margin-bottom: 50px;

  @media ${device.tablet} {
    justify-content: flex-end;
    flex: 0 0 48%;
  }
`;

const CreditsInfo = styled.div`
  flex: 0 0 100%;

  @media ${device.tablet} {
    flex: 0 0 48%;
  }
`;

const ArtistHeader = styled.p`
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 1.3rem !important;
`;

const ArtistsMain = styled.p`
  font-family: 'Noto Serif';
  font-size: 1.8rem !important;
  padding-bottom: 10px;
  border-bottom: 1px solid white;
`;

export default About;
