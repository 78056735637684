import React, { useState, useEffect } from 'react'
import firebase from 'firebase'

import NavigationWithFilter from './navigation-filter'
import Archive from './archive'
import Footer from './footer'

const Gallery = () => {

  const [entries, setEntries] = useState([]);
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [filterTerms, updateFilterTerms] = useState("");
  const [toggle, setToggle] = useState(false);
  const [resetEntries, updateResetEntries] = useState(false);

  let speakFilter = [];
  let thinkFilter = [];
  let feelFilter = [];
  let dreamFilter = [];
  let locationFilter = [];

  useEffect(() => {
    setTimeout(() => {
      document.body.id = "gallery";
      // get height of Gallery and set it to correct for react-tiger overflow
      document.getElementById("gallery").style.overflow = "visible";
      const pageHeight =
        document.getElementsByClassName("container")[0].clientHeight +
        "px";
      document.getElementById("root").style.height = pageHeight;
    }, 1000);

    return () => {
      document.body.id = "";
      document.getElementById("root").style.height = "100vh";
    };
  });

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("entries")
      .orderBy("entryNumber", "asc")
      .onSnapshot((snapshot) => {
        const newEntries = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEntries(newEntries);
        setFilteredEntries(newEntries);
      });
    return () => unsubscribe();
  }, []);

  if (entries.length > 0) {
    const categoryMap = Object.values(entries)
      .reduce(
        (concatedArr, item) => concatedArr.concat(Object.entries(item)),
        []
      )
      .reduce((result, [category, values]) => {
        result[category] = result[category] || [];
        result[category] = result[category].concat(values);
        return result;
      }, {});

    speakFilter = categoryMap.speak.filter(
      (item, pos) => categoryMap.speak.indexOf(item) === pos
    );
    thinkFilter = categoryMap.think.filter(
      (item, pos) => categoryMap.think.indexOf(item) === pos
    );
    feelFilter = categoryMap.feel.filter(
      (item, pos) => categoryMap.feel.indexOf(item) === pos
    );
    dreamFilter = categoryMap.dream.filter(
      (item, pos) => categoryMap.dream.indexOf(item) === pos
    );
    locationFilter = categoryMap.location.filter(
      (item, pos) => categoryMap.location.indexOf(item) === pos
    );
  }

  const clearFilter = () => {
    setFilteredEntries(entries);
    updateFilterTerms("");
    updateResetEntries(true);
  };

  return (
    <div className="container">
      <NavigationWithFilter
        speakFilter={speakFilter}
        thinkFilter={thinkFilter}
        feelFilter={feelFilter}
        dreamFilter={dreamFilter}
        locationFilter={locationFilter}
        setFilteredEntries={setFilteredEntries}
        entries={entries}
        setToggle={setToggle}
        toggle={toggle}
        updateFilterTerms={updateFilterTerms}
        filterTerms={filterTerms}
      />
      <Archive
        toggle={toggle}
        resetEntries={resetEntries}
        filteredEntries={filteredEntries}
        filterTerms={filterTerms}
        clearFilter={clearFilter}
      />
      <Footer />
    </div>
  );
};

export default Gallery
