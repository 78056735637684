import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "react-tiger-transition/styles/main.min.css";

import { I18nContextProvider } from "./i18n";

ReactDOM.render(
  <I18nContextProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </I18nContextProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();

