import React from 'react'
import styled from 'styled-components'

import FilterTermsContent from './filter-terms-content'

const FilterTerms = ({filterTerms, clearFilter}) => {

  return (
    <FilterTermsContainer>
        <FilterTermsContent filterTerms={filterTerms} clearFilter={clearFilter} />
    </FilterTermsContainer>
  );
}

const FilterTermsContainer = styled.div`
  width: 90vw;
  height: 20px;
`;

export default FilterTerms;
