import React, { useState, useRef, useLayoutEffect } from 'react'
import styled, { css } from 'styled-components'
import { CSSTransition } from 'react-transition-group'

// Helpers
import { device } from '../constants/responsive';

//  Components
import PngViewer from '../components/svg-viewer/png-viewer'
import FilterTerms from '../components/filter-terms';

//  Assets
import Loading from '../assets/loading.svg';

const Archive = ({ filteredEntries, toggle, resetEntries, filterTerms, clearFilter }) => {
  const [isLoaded, updateIsLoaded] = useState(false);

  /**
   *  once entries have loaded, fire LayoutEffect once
   *  and trigger Transition Group animations
   */
  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    updateIsLoaded(true);
  }, [filteredEntries]);


  return (
    <ArchiveSC toggle={toggle}>
      { filteredEntries.length > 0 &&
        <FilterTerms filterTerms={filterTerms} clearFilter={clearFilter} />
      }
      <ArchiveContainer>
        {filteredEntries.length > 0 ? (
          filteredEntries.map((entry) => (
            <CSSTransition
              in={isLoaded}
              timeout={200}
              classNames="gallery-symbols"
              key={entry.id}
            >
              <GallerySymbol className={resetEntries && "loaded"}>
                <a href={"/entry/" + entry.id}>
                  <PngViewer
                    totalSpeak={entry.speak.length}
                    totalThink={entry.think.length}
                    totalFeel={entry.feel.length}
                    totalDream={entry.dream.length}
                  />
                </a>
              </GallerySymbol>
            </CSSTransition>
          ))
        ) : (
          <LoadingContainer>
            <img src={Loading} alt="Loading..." />
          </LoadingContainer>
        )}
      </ArchiveContainer>
    </ArchiveSC>
  );
}

const ArchiveSC = styled.div`
  flex: 1 0 auto;
  margin-top: 25px;

  @media ${device.laptop} {
    margin-top: 50px;
  }
`;

const ArchiveContainer = styled.div`
  margin-top: 20px;
  width: 90vw;
  display: grid;
  grid-template-columns: repeat(3, calc(33.33% - 6.666667px));
  grid-gap: 7px 10px;
  transition: all 100ms ease-in-out;

  @media ${device.tablet} {
    grid-template-columns: repeat(8, calc(12.5% - 8.75px));
  }

  @media ${device.laptop} {
    grid-template-columns: repeat(11, calc(9.090909091% - 9.090909091px));
  }

  a {
    display: block;
  }

  ${({ toggle }) =>
    toggle &&
    `
    opacity: 0.5;
  `}
`;

const LoadingContainer = styled.div`
  grid-column: 1 / -1;
  text-align: center;
`;

// Programatically offset loading symbols
function createCSS() {
  let styles = '';

  for (let i = 0; i <= 500; i++) {
    styles += `
        &:nth-child(${i}) {
          transition: opacity 500ms;
          transition-delay: ${i * 5}ms;
        }
      `;
  }

  return css`
    ${styles}
  `;
}


const GallerySymbol = styled.div`
  ${createCSS()};
  opacity: 0;

  &.gallery-symbols-enter-done {
    opacity: 1;
  }

  &.loaded {
    opacity: 1;
  }

  a div {
    transition: all 150ms ease-out;

    &:hover {
      transform: scale(1.1);
    }
  }

`;

export default Archive
