import React from "react";

import Think01 from "./pngs/think-01.png";
import Think02 from "./pngs/think-02.png";
import Think03 from "./pngs/think-03.png";
import Think04 from "./pngs/think-04.png";
import Think05 from "./pngs/think-05.png";
import Think06 from "./pngs/think-06.png";
import Think07 from "./pngs/think-07.png";
import Think08 from "./pngs/think-08.png";
import Think09 from "./pngs/think-09.png";
import Think10 from "./pngs/think-10.png";

const ThinkForm = (props) => {
  switch (props.number) {
    case "0":
      return <img src={Think01} alt="Symbol for thinking in 1 language" />;
    case "1":
      return <img src={Think01} alt="Symbol for thinking in 1 language" />;
    case "2":
      return <img src={Think02} alt="Symbol for thinking in 2 languages" />;
    case "3":
      return <img src={Think03} alt="Symbol for thinking in 3 languages" />;
    case "4":
      return <img src={Think04} alt="Symbol for thinking in 4 languages" />;
    case "5":
      return <img src={Think05} alt="Symbol for thinking in 5 languages" />;
    case "6":
      return <img src={Think06} alt="Symbol for thinking in 6 languages" />;
    case "7":
      return <img src={Think07} alt="Symbol for thinking in 7 languages" />;
    case "8":
      return <img src={Think08} alt="Symbol for thinking in 8 languages" />;
    case "9":
      return <img src={Think09} alt="Symbol for thinking in 9 languages" />;
    default:
      return <img src={Think10} alt="Symbol for thinking in 10 languages" />;
  }
};

export default ThinkForm;
