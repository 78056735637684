const size = {
  mobile: "425px",
  tabletMin: "426px",
  tabletBig: "768px",
  laptop: "1024px",
  desktop: "2560px",
};

export const device = {
  mobile: `(max-width: ${size.mobile})`,
  tabletMin: `(min-width: ${size.tabletMin})`,
  tablet: `(min-width: ${size.tabletBig})`,
  laptop: `(min-width: ${size.laptop})`,
  desktop: `(min-width: ${size.desktop})`,
};
