import React from 'react'
import styled from 'styled-components'

const Filter = ({category, filter, filterIt}) => {
  filter.sort();

  return (
    <LanguageList className={category}>
      {filter.map((language, i) => (
        <li key={i}>
          <a
            data-language={language}
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              filterIt(e.target.dataset.language, category.toString());
            }}
          >
            {language}
          </a>
        </li>
      ))}
    </LanguageList>
  );
}

const LanguageList = styled.ul`
  li a {
    color: black;

    &:hover {
      color: #d1d1d1;
    }
  }
`;

export default Filter
