import React, { useContext } from "react";
import styled from "styled-components";

import { I18nContext } from "../i18n";

const LanguageSelect = (page) => {
  const isAbout = page['page'];

  /* Another hook here: useContext will receive a Context
  and return anything provided in the Provider */
  const { langCode, dispatch } = useContext(I18nContext);

  /* We will dispatch an action to set the language with the
  value of <select /> component. This will also change the
  translate method in the context to translate keys into
  the language we select */
  const onLanguageSelect = (e) => {
    dispatch({ type: "setLanguage", payload: e.target.value });
    localStorage.setItem('language', e.target.value);
  }

  const renderOption = (code) => (
    <option value={code} defaultValue={code === langCode}>
      {code}
    </option>
  );

  return (
    <>
      <MainSelector value={langCode} page={isAbout} onChange={onLanguageSelect}>
        {renderOption("ar")}
        {renderOption("bn")}
        {renderOption("can")}
        {renderOption("en")}
        {renderOption("es")}
        {renderOption("fa")}
        {renderOption("fr")}
        {renderOption("he")}
        {renderOption("hi")}
        {renderOption("it")}
        {renderOption("ja")}
        {renderOption("man")}
        {renderOption("mar")}
        {renderOption("pt")}
        {renderOption("pun")}
        {renderOption("ru")}
        {renderOption("ur")}
        {renderOption("yi")}
      </MainSelector>
      <span>&#8964;</span>
    </>
  );
};

const MainSelector = styled.select`
  border: none;
  color: #5d4749;
  text-transform: uppercase;
  font-weight: 700;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
  }

  ${({ page }) =>
    page === "about" &&
    `
      background: #15120f;
      color: white;
    `}
`;

export default LanguageSelect;
