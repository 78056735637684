import React from "react";
import styled from "styled-components";

import DreamForm from "./dream-form";
import FeelForm from "./feel-form";
import ThinkForm from "./think-form";
import SpeakForm from "./speak-form";

const PngViewer = ({ totalDream, totalFeel, totalThink, totalSpeak }) => {
  return (
    <ShapeCanvas>
      {totalDream > 0 && <DreamForm number={totalDream.toString()} />}
      {totalFeel > 0 && <FeelForm number={totalFeel.toString()} />}
      {totalThink > 0 && <ThinkForm number={totalThink.toString()} />}
      {totalSpeak > 0 && <SpeakForm number={totalSpeak.toString()} />}
    </ShapeCanvas>
  );
};

const ShapeCanvas = styled.div`
  position: relative;
  width: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &:nth-child(1) {
      position: relative;
      z-index: 4;
    }

    &:nth-child(2) {
      z-index: 3;
    }

    &:nth-child(3) {
      z-index: 2;
    }

    &:nth-child(4) {
      z-index: 1;
    }
  }
`;

export default PngViewer;
