import React, { useContext } from 'react'
import styled from 'styled-components'
import useOnclickOutside from 'react-cool-onclickoutside'
import {Link} from 'react-tiger-transition'

// Helpers
import { I18nContext } from "../i18n";
import { device } from '../constants/responsive';

// Components
import FilterContainer from './filter-container'
import LanguageSelect from './language-select'

// Assets
import Logo from "../assets/jaali-logo.svg"

const NavigationWithFilter = ({speakFilter, thinkFilter, feelFilter, dreamFilter, locationFilter, setFilteredEntries, entries, setToggle, toggle, updateFilterTerms, filterTerms}) => {
  const ref = useOnclickOutside(() => {
    setToggle(false);
  });

  const handleClickBtn = () => {
    setToggle(true);
  };

  const { translate } = useContext(I18nContext);

  return (
    <NavigationSC>
      <LogoBlock>
        <img src={Logo} alt="JAALI" />
      </LogoBlock>
      <ul className="nav-primary">
        <li>
          <Link className="nav-link" transition="glide-right" to="/about">
            {translate("nav_about")}
          </Link>
        </li>
        <li>
          <Link className="nav-link" transition="fade" to="/">
            {translate("nav_gallery")}
          </Link>
          <span onClick={handleClickBtn}>{translate("gallery_filters")} &#8964;</span>
          {toggle && (
            <div ref={ref}>
              <FilterContainer
                speakFilter={speakFilter}
                thinkFilter={thinkFilter}
                feelFilter={feelFilter}
                dreamFilter={dreamFilter}
                locationFilter={locationFilter}
                setFilteredEntries={setFilteredEntries}
                entries={entries}
                updateFilterTerms={updateFilterTerms}
                filterTerms={filterTerms}
                setToggle={setToggle}
              />
            </div>
          )}
        </li>
        <li className="nav-subtitle">
          <Link className="nav-link" transition="fade" to="/">
            {translate("jaali_subtitle")}
          </Link>
        </li>
        <li>
          <LanguageSelect />
        </li>
        <li>
          <Link className="nav-link" transition="glide-left" to="/new">
            {translate("nav_participate")} +
          </Link>
        </li>
      </ul>
    </NavigationSC>
  );
}

const NavigationSC = styled.div`
  width: 100vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media ${device.laptop} {
    margin-top: 50px;
  }

  .nav-primary {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    width: 90vw;
    padding: 0;
    z-index: 10;
    margin-top: 8px;

    > li {
      flex: 0 0 25%;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      color: black;
      font-size: 1.2rem;

      &.nav-subtitle {
        display: none;

        @media ${device.tablet} {
          display: block;
        }
      }

      &:nth-child(1) {
        text-align: left;
      }

      &:nth-child(2) {
        display: flex;
        flex-direction: column;
        margin-top: 18px;
        text-align: left;

        span {
          cursor: pointer;
          font-size: 1.2rem;
          text-transform: initial;
          color: #666;
          margin-top: 5px;

          &:hover {
            color: black;
          }
        }
      }

      &:nth-child(4) {
        flex: 0 0 20%;
      }

      &:nth-child(5) {
        flex: 0 0 30%;
      }
    }

    @media ${device.tablet} {
      > li {
        flex: 0 0 20%;
        text-align: left;

        &:nth-child(2) {
          a {
            max-width: max-content;
          }
        }

        &:nth-child(3) {
          flex: 1 0 auto;
          text-align: center;
        }

        &:nth-child(4),
        &:nth-child(5) {
          text-align: right;
          flex: 0 0 20%;
        }
      }
    }

    @media ${device.laptop} {
      > li {
        font-size: 1.6rem;
      }
    }
  }

  .nav-link {
    color: #5d4749;
    text-transform: uppercase;
    font-weight: 700;
    transition: all 100ms ease-in-out;
    border-bottom: 1px solid transparent;

    &:hover {
      color: #666666;
      border-bottom: 1px solid #666666;
    }
  }
`;

const LogoBlock = styled.div`
  width: 90vw;
  display: flex;
  justify-content: center;

  img {
    width: 50vw;
    max-width: 150px;

    ${device.tablet} {
      max-width: 200px;
    }
  }
`;

export default NavigationWithFilter
