import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyBL290BoHb7G0mfVcr_1AE166IEOc7FFGE",
  authDomain: "isf-jamesco.firebaseapp.com",
  databaseURL: "https://isf-jamesco.firebaseio.com",
  projectId: "isf-jamesco",
  storageBucket: "isf-jamesco.appspot.com",
  messagingSenderId: "447050274155",
  appId: "1:447050274155:web:a8a21801871b73d5ada82e"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase
