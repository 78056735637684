import React from "react";
import styled, {css} from "styled-components";
import { CSSTransition } from "react-transition-group";

// Components
import DreamForm from './dream-form'
import FeelForm from './feel-form'
import ThinkForm from './think-form'
import SpeakForm from './speak-form'

const SvgViewer = ({ totalDream, totalFeel, totalThink, totalSpeak }) => {

  return (
    <ShapeCanvas>
      <CSSTransition in={totalDream > 0} timeout={200} className="new-entry">
        <div>
          {totalDream > 0 && <DreamForm number={totalDream.toString()} />}
        </div>
      </CSSTransition>
      <CSSTransition in={totalFeel > 0} timeout={200} className="new-entry">
        <div>
          {totalFeel > 0 && <FeelForm number={totalFeel.toString()} />}
        </div>
      </CSSTransition>

      <CSSTransition in={totalThink > 0} timeout={200} className="new-entry">
        <div>
          {totalThink > 0 && <ThinkForm number={totalThink.toString()} />}
        </div>
      </CSSTransition>
      <CSSTransition in={totalSpeak > 0} timeout={200} className="new-entry">
        <div>
          {totalSpeak > 0 && <SpeakForm number={totalSpeak.toString()} />}
        </div>
      </CSSTransition>
    </ShapeCanvas>
  );
}

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

// Generate random numbers for animating shapes
function randomRotation() {
  let styles = '';

  for (let i = 0; i <= 3; i++) {
    styles += `
      transform: rotate(${getRandomArbitrary(-180, 180)}deg);
    `;
  }

  return css`
    ${styles}
  `;
}

const ShapeCanvas = styled.div`
  position: relative;
  width: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &:nth-child(1) {
      position: relative;
      z-index: 4;
    }

    &:nth-child(2) {
      z-index: 3;
    }

    &:nth-child(3) {
      z-index: 2;
    }

    &:nth-child(4) {
      z-index: 1;
    }
  }

  .new-entry {
    ${randomRotation()};
    transition: all 150ms ease-in-out;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.enter-done {
      opacity: 1;
      transform: rotate(0deg);
    }
  }
`;

export default SvgViewer
