import React, { useContext } from "react";
import styled from "styled-components";

// Helpers
import { I18nContext } from "../i18n";
import { device } from '../constants/responsive';

const FilterTermsContent = ({filterTerms, clearFilter}) => {
  const { translate } = useContext(I18nContext);

  return (
    <FilterTermsContentContainer>
      {filterTerms.length > 0 ? (
        <>
          <p>
            <strong>{translate("gallery filters_current")}: </strong>
            {translate("gallery_filters_filtered")} <strong>{filterTerms[0]}</strong> in
            <strong> {filterTerms[1]}</strong>.
          </p>
          <button data-language="clear" onClick={clearFilter}>
            {translate("gallery_filters_clear")} ✖
          </button>
        </>
      ) : (
        <p>
          <strong>{translate("gallery_filters_default")}</strong>
        </p>
      )}
    </FilterTermsContentContainer>
  );
}

const FilterTermsContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media ${device.tabletMin} {
    height: 25px;
  }

  p {
    margin-bottom: 0;
    flex: 0 1 auto;
    font-size: 1.2rem;
    margin-right: 10px;
  }

  button {
    flex: 0 0 100px;
    padding: 4px 10px 5px;
    border-radius: 6px;
    background: #e6e6e6;
    border: none;
    font-size: 1.2rem;

    &:hover {
      cursor: pointer;
      background-color: lightgrey;
    }
  }
`;

export default FilterTermsContent;
